import React, { useEffect, useState } from 'react';
import { Image } from "react-bootstrap";
import styles from "./ImageSlider.module.css";

interface IProps {
    images: string[];
}

const defaultImageUrl = "/images/placeholder.png";

const ImageSlider: React.FC<IProps> = ({
    images
}) => {
    const [activeImageIndex, setActiveImageIndex] = useState<number>(0);

    useEffect(() => {
        setActiveImageIndex(0);
    }, [images]);

    return (
        <div className={styles.container}>
            <div
                className={`${styles.controls} ${styles.prevIcon} ${images?.length <= 1 && styles.hideIcon}`}
                onClick={() => setActiveImageIndex(prev => (prev - 1 + images?.length) % images?.length)}
            >
                <i className="fa-solid fa-angle-left"></i>
            </div>
            <div
                className={`${styles.controls} ${styles.nextIcon} ${images?.length <= 1 && styles.hideIcon}`}
                onClick={() => setActiveImageIndex(prev => (prev + 1) % images?.length)}
            >
                <i className="fa-solid fa-angle-right"></i>
            </div>
            <Image
                src={images?.[activeImageIndex] || defaultImageUrl}
                alt=""
                className={styles.image}
            />
        </div>
    )
};

export default ImageSlider;