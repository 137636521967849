import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import LoginProvider from './contexts/LoginContext';
import ApiProvider from './contexts/ApiContext';
import DataProvider from './contexts/DataContext';
import ExtraDataProvider from './contexts/ExtraDataContext';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <LoginProvider>
      <ApiProvider>
        <DataProvider>
          <ExtraDataProvider>
            <App />
          </ExtraDataProvider>
        </DataProvider>
      </ApiProvider>
    </LoginProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

