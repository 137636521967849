import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useApi } from '../../../contexts/ApiContext';
import { ReservedImageKey } from '../../../types';
import DashboardNav from '../DashboardNav';
import { FiltersView } from '../_sidebar/Views';
import styles from './Main.module.css';
import { ARView, Gallery, ThreeDView } from './Views';
import ProductInfo from './Views/ProductInfo';
import ProductView from './Views/ProductView';

interface IProps {
    loading: boolean;
    productsLoading: boolean;
    models: any;
    product: any;
    selectedModelIndex: number;
    setSelectedModelIndex: any;

    products: any;
    selectedProductIndex: number;
    setSelectedProductIndex: any;
    categories: any;
    selectedCategories: any;
    setSelectedCategories: any;
    lastProductElementRef: any;
    query: string;
    setQuery: any;
    showFilter: boolean;
    setShowFilter: any;
}

export enum ViewType {
    PRODUCT_VIEW = "Product",
    THREED_VIEW = "Visualize",
    AR_VIEW = "My Space"
}

const Views = [ViewType.PRODUCT_VIEW, ViewType.THREED_VIEW, ViewType.AR_VIEW];

const MainSection: React.FC<IProps> = ({
    loading,
    productsLoading,
    models,
    product,
    selectedModelIndex,
    setSelectedModelIndex,

    products,
    selectedProductIndex,
    setSelectedProductIndex,
    categories,
    selectedCategories,
    setSelectedCategories,
    lastProductElementRef,
    query,
    setQuery,
    showFilter,
    setShowFilter
}) => {
    const { fetchProduct } = useApi();
    const [model, setModel] = useState<any>(null);
    const [modelLoading, setModelLoading] = useState<boolean>(true);
    const [view, setView] = useState<string>(Views[0]);
    const [textureImage, setTextureImage] = useState<string>();
    const [showGallery, setShowGallery] = useState<boolean>(false);
    const mainSectionRef = useRef<any>();

    useEffect(() => {
        let texture = product?.media_items?.find((item: any) => item?.name === ReservedImageKey.TEXTURE_IMAGE)?.url;
        if (texture) {
            setTextureImage(texture);
        } else {
            setTextureImage(undefined);
        }
    }, [product]);

    const getModel = useCallback(async (productId) => {
        try {
            setModelLoading(true);
            const data = await fetchProduct(productId);
            setModel(data ?? null);
            // console.log(data);
        } catch (err) {
            window.alert(err);
        } finally {
            setModelLoading(false);
        }
    }, [fetchProduct]);

    useEffect(() => {
        if (models?.[selectedModelIndex]?.product) {
            getModel(models?.[selectedModelIndex]?.product);
        }
    }, [selectedModelIndex, getModel, models]);

    const getView = () => {
        switch (view) {
            case ViewType.PRODUCT_VIEW:
                return <ProductView
                    product={product}
                    loading={productsLoading}
                />
            case ViewType.THREED_VIEW:
                return <ThreeDView
                    loading={loading}
                    modelLoading={modelLoading}
                    models={models}
                    model={model}
                    selectedModelIndex={selectedModelIndex}
                    setSelectedModelIndex={setSelectedModelIndex}
                    textureUrl={textureImage ?? product?.images?.[0] ?? product?.thumbnail}
                />
            case ViewType.AR_VIEW:
                return <ARView
                    product={product}
                    loading={productsLoading}
                />
        }
    };

    return (
        <div
            className={`${styles.mainSection} styledScrollbar ${(showGallery || showFilter) && styles.noScroll}`}
            ref={mainSectionRef}
        >
            <div className={styles.mainSectionNavbar}>
                <DashboardNav
                    showFilter={showFilter}
                    setShowFilter={setShowFilter}
                    categories={categories}
                    selectedCategories={selectedCategories}
                />
            </div>

            {showFilter &&
                <div className={`${styles.mainSectionFilters} styledScrollbar`}>
                    <FiltersView
                        categories={categories}
                        setSelectedCategories={setSelectedCategories}
                        selectedCategories={selectedCategories}
                        setShowFilter={setShowFilter}
                    />
                </div>
            }

            <div
                className={`${styles.moveToTopIcon}`}
                onClick={() => {
                    mainSectionRef.current.scroll(0, 0);
                }}
            >
                <i className="fa-solid fa-arrow-up"></i>
            </div>

            <div className={`${styles.model}`}>
                <div className={`${styles.views}`}>
                    {Views.map((viewType, idx) => (
                        <div
                            key={idx}
                            className={`${styles.viewItem} ${viewType === view && styles.active}`}
                            onClick={() => setView(viewType)}
                        >
                            {viewType}
                        </div>
                    ))}
                </div>
                {getView()}
            </div>
            <div className={styles.productInfo}>
                <ProductInfo
                    product={product}
                    loading={productsLoading}
                    onViewGalleryClick={() => {
                        setShowGallery(true);
                        if (typeof window !== "undefined") {
                            mainSectionRef.current.scroll(0, 0);
                        }
                    }}

                    products={products ?? []}
                    selectedProductIndex={selectedProductIndex}
                    setSelectedProductIndex={setSelectedProductIndex}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    lastProductElementRef={lastProductElementRef}
                    query={query}
                    setQuery={setQuery}
                />
            </div>
            {product && product?.media_items?.filter((item: any) => !item?.name?.startsWith("__"))?.length > 0 &&
                <div
                    className={styles.galleryBtn}
                    onClick={() => setShowGallery(true)}
                >
                    <div style={{ color: "#CFD4D9" }}>
                        <i className="fa-solid fa-angle-up"></i>
                    </div>
                    <div style={{ color: "#878E95" }}>
                        View Gallery
                    </div>
                </div>
            }
            {showGallery &&
                <div
                    className={`${styles.galleryOverlay} transparentScrollbar`}
                >
                    <div
                        className={styles.galleryCloseIcon}
                        onClick={() => setShowGallery(false)}
                    >
                        <i
                            className="fa-solid fa-angle-down"
                        />
                    </div>
                    <Gallery
                        product={product}
                        loading={productsLoading}
                    />
                </div>
            }
        </div>
    )
}

export default MainSection;