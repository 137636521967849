import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLogin } from '../../contexts/LoginContext';
import { LoginDetailsType } from '../../screens/Auth/Auth';
import { LoginType } from './Step1';
import helloarLogoSrc from "../../assets/images/helloar_logo.svg";

interface IProps {
    loginDetails: LoginDetailsType | null;
    redirectTo: string;
}

const Step2: React.FC<IProps> = ({ loginDetails, redirectTo }) => {
    const { setState, verifyOtp } = useLogin();
    const [otp, setOtp] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            setLoading(true);
            const loginData = await verifyOtp(loginDetails?.requestId, otp);
            const userId = loginData.account_id;
            const authToken = `Token ${loginData.access_token}`;
            setState({
                loggedIn: true,
                token: authToken,
                userId
            });
            window.location.href = redirectTo;
        } catch (err) {
            window.alert(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h4 className='mb-4'>
                <img src={helloarLogoSrc} alt="" />
            </h4>
            <p>
                OTP successfully sent to your
                {loginDetails?.loginType === LoginType.PHONE ?
                    ' phone' :
                    ' email'
                }
            </p>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Control
                        placeholder='OTP'
                        required
                        autoFocus
                        value={otp}
                        onChange={({ target }) => setOtp(target.value)}
                    />
                </Form.Group>
                <button
                    className='btnPrimary mt-3'
                    disabled={loading}
                >
                    {loading ?
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        'Submit'
                    }
                </button>
            </Form>
        </div>
    )
};

export default Step2;