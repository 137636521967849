import React from 'react';
import styles from './FullScreenError.module.css';

interface IProps {
    msg: string;
}

const FullScreenError: React.FC<IProps> = ({ msg }) => {
    return (
        <div className={styles.fullScreenError}>
            <div className={styles.errorMsg}>
                {msg}
            </div>
        </div>
    )
};

export default FullScreenError;