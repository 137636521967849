import React, { useState } from 'react';
import { Image } from "react-bootstrap";
import { useParams, Link, useNavigate } from 'react-router-dom';
import ProductsModal from '../../components/ProductsModal';
import { useData } from '../../contexts/DataContext';
import styles from "./MoodboardCreator.module.css";

import DefaultLogoSrc from "../../assets/images/brand_logo.svg";

const initialImages = Array(6).fill(0);
// const pngFiles = [
//     "https://3.bp.blogspot.com/-zmt8uoO7zMI/TbBXWRkm3RI/AAAAAAAAAZ0/F8gzzOAktdU/s1600/plant02.png",
//     "https://3.bp.blogspot.com/_rtMlwCpL8jQ/TPOM8wTM4vI/AAAAAAAAAik/g7OyWqp-olc/s1600/colorstoneswirl2.png",
//     "http://www.pngmart.com/files/3/Paint-Texture-Transparent-PNG.png",
//     "https://1067544234.rsc.cdn77.org/photoThumbnail/2015-08/14401-165557/cracky-decals-photo-texture-of-cracked-decal_640v640.png",
//     "http://fc01.deviantart.net/fs71/i/2014/049/d/e/cobwebs_texture_stock_png_by_lubman-d77058u.png",
//     "https://pngimg.com/uploads/drops/drops_PNG13512.png"
// ];

const MoodboardCreator: React.FC = () => {
    const [images, setImages] = useState<string[]>(initialImages);
    const [slotIndexToBeUpdated, setSlotIndexToBeUpdated] = useState<number | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [backgroundColor, setBackgroundColor] = useState<string>("#ffffff");
    const [imageError, setImageError] = useState<boolean>(false);
    const navigate = useNavigate();


    const { viewerData } = useData();
    const { viewerId } = useParams();

    const updateImage = (imageSrc: string) => {
        const tempImages = [...images];
        if (slotIndexToBeUpdated != null) {
            tempImages[slotIndexToBeUpdated] = imageSrc;
            setImages(tempImages);
        }
        setSlotIndexToBeUpdated(null);
        setShowModal(false);
    };


    const openProductsModal = (idx: number) => {
        setSlotIndexToBeUpdated(idx);
        setShowModal(true);
    };

    return (
        <div className={styles.container}>
            <div className={`${styles.topBar}`}>
                <div className={`w-100 d-flex justify-content-between align-items-center`}>
                    <div className={styles.leftTopBar}>
                        <Link to={`/${viewerId}`} className='normalLink'>
                            <div className={styles.logo}>
                                <Image
                                    src={
                                        imageError
                                            ? DefaultLogoSrc
                                            : viewerData?.brand_details?.logo
                                                ? viewerData?.brand_details?.logo
                                                : DefaultLogoSrc
                                    }
                                    className={imageError
                                        ? styles.defaultLogoStyle
                                        : viewerData?.brand_details?.logo
                                            ? styles.logoImage
                                            : styles.defaultLogoStyle}
                                    onError={() => {
                                        setImageError(true);
                                    }}
                                />
                            </div>
                        </Link>
                    </div>
                    <h5 className="pb-0 mb-0">
                        Moodboard
                    </h5>
                    <div className={styles.rightTopBar}>
                        {/* <label className='btnOutlinePrimary btn-sm rounded' htmlFor="favcolor">
                            set background
                        </label> */}
                        <input
                            type="color"
                            id="favcolor"
                            style={{ marginRight: "15px" }}
                            value={backgroundColor}
                            onChange={({ target }) => setBackgroundColor(target.value)}
                        />
                        <button
                            onClick={() => navigate(`/${viewerId}/catalogue`)}
                            style={{ fontSize: "24px" }}
                        >
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div
                className={`${styles.moodboardContainer} pt-1 pb-3`}
                style={{ backgroundColor: backgroundColor }}
            >
                <div className={`${styles.leftControls}`}>
                    {/* <button className='btn-light btn-sm rounded'>
                        choose template
                    </button> */}
                </div>
                <div className={`${styles.rightControls}`}>
                    {/* <input
                        type="color"
                        id="favcolor"
                        style={{ marginLeft: "5px" }}
                        value={backgroundColor}
                        onChange={({ target }) => setBackgroundColor(target.value)}
                    /> */}
                </div>

                <div className={styles.imagesContainer}>
                    {images.map((src, idx) => (
                        <div
                            className={styles.imageContainer}
                            onClick={() => openProductsModal(idx)}
                            key={idx}
                        >
                            {src ?
                                <>
                                    <img
                                        className={styles.image}
                                        src={src}
                                        alt=""
                                    />
                                    {/* <img
                                        className={styles.pngImage}
                                        src={pngFiles[idx]}
                                        alt=""
                                    /> */}
                                </>

                                :
                                <div className={`${styles.emptySlot}`}>
                                    <i className="fa-solid fa-plus"></i>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>

            <ProductsModal
                showModal={showModal}
                hide={() => setShowModal(false)}
                onProductClick={(imageSrc) => updateImage(imageSrc)}
            />

        </div>
    )
};

export default MoodboardCreator;