import { useEffect, useState } from 'react';

function getInitialValue(key:string,initialValue:any){
	const data = localStorage.getItem(key) && JSON.parse(localStorage.getItem(key) as string);
	if(data) return data;
	if(initialValue instanceof Function) return initialValue();
	return initialValue;
}

const useLocalStorage = (key:string,initialValue:any) => {
  const [value,setValue] = useState<any>(getInitialValue(key,initialValue)); 

	useEffect(()=>{
		localStorage.setItem(key,JSON.stringify(value));
	},[value,key]);

  return [
      value,setValue
	];
};

export default useLocalStorage;