import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => {
    return (
        <div className='centerLoader'>
            <Spinner animation="grow" />
        </div>
    )
};

export default Loader;