import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductsView } from '../_dashboard/_sidebar/Views';
import styles from "./ProductsWithFilter.module.css";

interface IProps {
    categories: any;
    selectedCategories: any;
    setSelectedCategories: any;
    loading: boolean;
    products: any;
    selectedProductIndex: number;
    setSelectedProductIndex: any;
    lastProductElementRef: any;
    onProductClick?: (image: string) => void;
    query: string;
    setQuery: any;
}

const ProductsWithFilter: React.FC<IProps> = ({
    loading,
    products,
    selectedProductIndex,
    setSelectedProductIndex,
    categories,
    selectedCategories,
    setSelectedCategories,
    lastProductElementRef,
    onProductClick,
    query,
    setQuery
}) => {
    const navigate = useNavigate();
    const { viewerId } = useParams();

    return (
        <div className={styles.productsWithFilter}>
            <div className={`${styles.sideOptions} ${!!onProductClick && 'd-flex'} mb-3`}>
                <input
                    className={styles.searchInput}
                    value={query}
                    placeholder='search products eg. "oak"'
                    onChange={({ target }) => setQuery(target.value)}
                />
                <div className={`${styles.search}`}>
                    {loading ?
                        <Spinner animation='border' size='sm' style={{ color: "lightgrey" }} />
                        :
                        <img src="/images/search.png" alt="search" />
                    }
                </div>
            </div>
            <div className={`${styles.sideActions} mb-4`}>
                {!!!onProductClick && <div className={styles.moodBtn}>
                    <button onClick={() => navigate(`/${viewerId}/moodboard-creator`)}>
                        View Moodboard
                    </button>
                </div>}
            </div>

            <ProductsView
                loading={loading}
                products={products}
                selectedProductIndex={selectedProductIndex}
                setSelectedProductIndex={setSelectedProductIndex}
                lastProductElementRef={lastProductElementRef}
                onProductClick={onProductClick}
            />
        </div>
    )
};

export default ProductsWithFilter;