import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom'
import { useData } from '../contexts/DataContext';
import FullScreenError from './FullScreenError';
import LandingScreenAnim from './LandingScreenAnim';

const ParentComponent = () => {
    const { viewerId } = useParams();
    const { getViewer, getViewerGallery, viewerError, viewerLoading } = useData();

    useEffect(() => {
        getViewer(viewerId);
    }, [viewerId, getViewer]);

    useEffect(() => {
        if (viewerId)
            getViewerGallery(viewerId);
    }, [viewerId, getViewerGallery]);

    if (viewerError) {
        return <FullScreenError msg={viewerError} />
    }

    if (viewerLoading) {
        return <LandingScreenAnim />
    }

    return (
        <>
            <Outlet />
        </>
    )
}

export default ParentComponent;