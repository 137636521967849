import { v4 as uuidv4 } from "uuid";
import { MediaType } from "../types";

export const doesThreeDeeExist = (modelData: any): boolean => {
  if (modelData.three_dee_model?.glb) {
    return true;
  } else return false;
};

export const generateUUID = () => {
  let uuid = uuidv4();
  return uuid;
};

const getMediaType = (item:any)=>{
  let imageMatchRegex = /\.(jpg|jpeg|png)$/i;
  let videoMatchRegex = /\.(mp4)$/i;
  let pdfMatchRegex = /\.(pdf)$/i;
  if(imageMatchRegex.test(item?.url)) return MediaType.IMAGE;
  if(videoMatchRegex.test(item?.url)) return MediaType.VIDEO;
  if(pdfMatchRegex.test(item?.url)) return MediaType.PDF;
  return null;
};

export const getSanitizedMediaItems = (mediaItems:any) => {
  return mediaItems
  ?.filter((item:any)=>!!item?.url)
  ?.map((item:any)=>{
    return {
      ...item,
      type: getMediaType(item)
    }
  })
};