import React, { useCallback, useMemo, useState } from 'react';
import styles from './Category.module.css';
import CategoryList from './CategoryList';

const placeholderImages = [
    "/images/placeholder_images/placeholder1.png"
];

interface IProps {
    data: any;
    setSelectedCategories: any;
    selectedCategories: any;
}

const ProductItem: React.FC<IProps> = ({
    data,
    setSelectedCategories,
    selectedCategories
}) => {
    const [showChildren, setShowChildren] = useState<boolean>(false);

    const isChecked = useMemo(() => {
        return selectedCategories?.find((item: any) => item?.id === data?.id) ? true : false;
    }, [selectedCategories, data]);

    const unCheckCategory = useCallback((category: any) => {
        setSelectedCategories((prev: any) => {
            return prev?.filter((item: any) => item?.id !== category?.id);
        });
        if (category?.children && category?.children?.length) {
            for (let i = 0; i < category?.children?.length; i++) {
                unCheckCategory(category?.children?.[i]);
            }
        }
    }, [setSelectedCategories]);
    const checkCategory = useCallback((category: any) => {
        if (!selectedCategories?.find((item: any) => item?.id === category?.id)) {
            setSelectedCategories((prev: any) => {
                return [
                    ...prev,
                    {
                        id: category?.id,
                        name: category?.name,
                        thumbnail: category?.thumbnail
                    }
                ];
            });
        }
        if (category?.children && category?.children?.length) {
            for (let i = 0; i < category?.children?.length; i++) {
                checkCategory(category?.children?.[i]);
            }
        }
    }, [selectedCategories, setSelectedCategories]);

    const handleCategoryChange = useCallback(() => {
        if (isChecked) {
            unCheckCategory(data);
        } else {
            checkCategory(data);
        }
    }, [isChecked, checkCategory, unCheckCategory, data]);

    return (
        <div
            className={`${styles.categoryItem} ${showChildren && styles.childrenOpened}`}
        >
            <div className=''>
                <div>

                    <div className={styles.imageContainer}>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCategoryChange}
                            id={`check-${data?.id}`}
                        />
                        <img
                            src={data?.thumbnail || placeholderImages[0]}
                            alt=""
                            className={styles.image}
                            onClick={handleCategoryChange}
                        />
                    </div>
                    <div className={`d-flex justify-content-between align-items-center ${styles.categoryLabel}`}>
                        <label
                            htmlFor={`check-${data?.id}`}
                        >
                            <span>{data?.name}</span>
                        </label>
                        {data?.children && data?.children?.length > 0 &&
                            <span
                                onClick={() => setShowChildren(prev => !prev)}
                                className='pointer'
                            >
                                {showChildren ?
                                    <i className="fa-solid fa-angle-up"></i>
                                    :
                                    <i className="fa-solid fa-angle-down"></i>
                                }
                            </span>
                        }
                    </div>
                </div>

            </div>

            {showChildren && data?.children && data?.children?.length > 0 &&
                <CategoryList
                    categories={data?.children}
                    setSelectedCategories={setSelectedCategories}
                    selectedCategories={selectedCategories}
                />
            }
        </div>
    )
}

export default ProductItem;