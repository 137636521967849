import React, { useContext, useState } from 'react';

const ExtraDataContext = React.createContext<any>({});

export function useExtraData() {
    return useContext(ExtraDataContext);
}

const ExtraDataProvider = ({ children }: any) => {
    const [firstTimeLandReport, setFirstTimeLandReport] = useState<boolean>(true);
    const [categoryClicked, setCategoryClicked] = useState<any>();

    const data = {
        firstTimeLandReport,
        setFirstTimeLandReport,
        categoryClicked,
        setCategoryClicked
    };

    return (
        <ExtraDataContext.Provider value={data}>
            {children}
        </ExtraDataContext.Provider>
    )
};

export default ExtraDataProvider;