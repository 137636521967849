import React from 'react';
import styles from './Category.module.css';
import CategoryItem from './CategoryItem';

interface IProps {
    categories: any;
    setSelectedCategories: any;
    selectedCategories: any;
}

const ProductList: React.FC<IProps> = ({
    categories,
    setSelectedCategories,
    selectedCategories
}) => {
    return (
        <div className={`${styles.categoryList} styledScrollbar`}>
            {
                categories?.map((data: any, idx: number) => (
                    <CategoryItem
                        key={data?.id}
                        data={data}
                        setSelectedCategories={setSelectedCategories}
                        selectedCategories={selectedCategories}
                    />
                ))
            }
        </div>
    )
}

export default ProductList;