import React from 'react';
// import { useData } from '../../../../contexts/DataContext';
import { getSanitizedMediaItems } from '../../../../utilities';
import ImageGrid from '../../../ImageGrid';
import styles from '../Main.module.css';

interface IProps {
    product: any;
    loading: boolean;
}

const Gallery: React.FC<IProps> = ({ product, loading }) => {
    // const { galleryData } = useData();

    return (
        <>
            <div className={styles.gallery}>
                <ImageGrid
                    mediaItems={getSanitizedMediaItems(product?.media_items?.filter((item: any) => !item?.name?.startsWith("__")) ?? [])}
                    loading={loading}
                    gridColumns={2}
                />
            </div>
        </>
    )
};

export default Gallery;