import React, { useState } from 'react';
import styles from './Auth.module.css';
import Navbar from '../../components/_navbar/Navbar';
import { Step1, Step2 } from '../../components/_auth';
import { useParams } from 'react-router-dom';

export interface LoginDetailsType {
    loginType: string;
    email: string;
    phone: string;
    requestId: string;
}

const Auth = (props: any) => {
    const { viewerId } = useParams();
    const redirectTo = `/${viewerId}/catalogue`;
    const [step, setStep] = useState<number>(1);
    const [loginDetails, setLoginDetails] = useState<LoginDetailsType | null>(null);

    return (
        <>
            <Navbar />
            <div style={{ marginTop: "150px" }}></div>
            <div className={`${styles.authScreen} container`}>
                <div className={styles.authContainer}>
                    {step === 1 ?
                        <Step1 setStep={setStep} setLoginDetails={setLoginDetails} />
                        :
                        <Step2
                            loginDetails={loginDetails}
                            redirectTo={redirectTo}
                        />
                    }
                </div>
            </div>
        </>
    )
};

export default Auth;