import React, { useState } from 'react';
import { Spinner } from "react-bootstrap";
import styles from './ImageGrid.module.css';
import ImageGridItem from './ImageGridItem';
import ImageModal from './ImageModal';

interface IProps {
    mediaItems: string[];
    gridColumns: number;
    loading: boolean;
}

const ImageGrid: React.FC<IProps> = ({ mediaItems, gridColumns, loading }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedMediaIndex, setSelectedMediaIndex] = useState<number>(0);

    const show = (index: number) => {
        setSelectedMediaIndex(index);
        setShowModal(true);
    };
    const hide = () => {
        setShowModal(false);
    };

    const prev = () => {
        let prevIndex = (selectedMediaIndex - 1 + mediaItems?.length) % mediaItems?.length;
        setSelectedMediaIndex(prevIndex);
    };
    const next = () => {
        let nextIndex = (selectedMediaIndex + 1) % mediaItems?.length;
        setSelectedMediaIndex(nextIndex);
    };

    if (mediaItems?.length === 0) {
        return (
            <>
                <div className={`${styles.noDataDiv} border p-3`}>
                    {loading ?
                        <Spinner animation="border" />
                        :
                        <p className={`${styles.noDataText} text-fav-grey`}>No Media Items Available.</p>
                    }
                </div>
            </>
        )
    }

    return (
        <>
            <div
                className={`${styles.imageGridContainer} styledScrollbar`}
                style={{ gridTemplateColumns: `repeat(${gridColumns}, 1fr)` }}
            >
                {mediaItems?.map((mediaItem: any, idx: number) => (
                    <ImageGridItem
                        key={idx}
                        index={idx}
                        show={show}
                        mediaItem={mediaItem}
                    />
                ))}
            </div>
            {showModal &&
                <ImageModal
                    hide={hide}
                    mediaItem={mediaItems?.[selectedMediaIndex]}
                    prev={prev}
                    next={next}
                />
            }
        </>
    )
};

export default ImageGrid;