import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLogin } from '../../contexts/LoginContext';
import styles from './Auth.module.css';
import helloarLogoSrc from "../../assets/images/helloar_logo.svg";

interface IProps {
    setStep: any;
    setLoginDetails: any;
}
export enum LoginType {
    PHONE = "phone",
    EMAIL = "email"
}

const Step1: React.FC<IProps> = ({ setStep, setLoginDetails }) => {
    const { requestOtp } = useLogin();
    const [loginType, setLoginType] = useState<string>(LoginType.PHONE);
    const [phone, setPhone] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        console.log(loginType, phone, email);
        const data = {
            email: loginType === LoginType.EMAIL ? email : null,
            phone: loginType === LoginType.PHONE ? phone : null
        };
        console.log('data - ', data);
        try {
            setLoading(true);
            let email = data.email;
            let phone = data.phone;
            const requestId = await requestOtp(email, phone, "web");
            setLoginDetails({
                loginType,
                email: data.email,
                phone: data.phone,
                requestId: requestId,
            });
            setStep(2);
        } catch (err) {
            window.alert(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h4 className='mb-4'>
                <img src={helloarLogoSrc} alt="" />
            </h4>
            <div className={`${styles.loginTypeGroup} mb-3`}>
                <div
                    className={`${styles.loginType} ${loginType === LoginType.PHONE && styles.active}`}
                    onClick={() => setLoginType(LoginType.PHONE)}
                >
                    Phone
                </div>
                <div
                    className={`${styles.loginType} ${loginType === LoginType.EMAIL && styles.active}`}
                    onClick={() => setLoginType(LoginType.EMAIL)}
                >
                    Email
                </div>
            </div>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    {loginType === LoginType.PHONE ?
                        <Form.Control
                            placeholder='Enter Phone'
                            required
                            autoFocus
                            value={phone}
                            onChange={({ target }) => setPhone(target.value)}
                        />
                        :
                        <Form.Control
                            placeholder='Enter Email'
                            type="email"
                            required
                            autoFocus
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                        />
                    }
                </Form.Group>
                <div className="d-flex justify-content-end align-items-center">
                    <button
                        className='p-0 mt-3'
                        disabled={loading}
                    >
                        {loading ?
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            :
                            <div className={styles.nextIcon}>
                                <i className="fa-solid fa-circle-chevron-right"></i>
                            </div>
                        }
                    </button>
                </div>
            </Form>
        </div>
    )
};

export default Step1;