import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProductsWithFilter from '../../../ProductsWithFilter';
import styles from '../Main.module.css';

interface IProps {
    product: any;
    loading: boolean;
    onViewGalleryClick: any;

    products: any;
    selectedProductIndex: number;
    setSelectedProductIndex: any;
    categories: any;
    selectedCategories: any;
    setSelectedCategories: any;
    lastProductElementRef: any;
    query: string;
    setQuery: any;
}

const ProductInfo: React.FC<IProps> = ({
    product,
    loading,
    onViewGalleryClick,

    products,
    selectedProductIndex,
    setSelectedProductIndex,
    categories,
    selectedCategories,
    setSelectedCategories,
    lastProductElementRef,
    query,
    setQuery
}) => {

    return (
        <>
            <Col xs={12} className="p-5">
                {product ? <>
                    {product?.media_items?.filter((item: any) => !item?.name?.startsWith("__"))?.length > 0 &&
                        <Row>
                            <Col xs={12} className="p-0">
                                <div className={`mb-2 ${styles.viewGalleryBtn} p-0`}>
                                    <button
                                        className='customOutlineBtn'
                                        onClick={onViewGalleryClick}
                                    >
                                        <i className="fa-regular fa-image"></i>
                                        &nbsp;
                                        View Gallery
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xs={12} sm={6} className="p-0">
                            <h3 className={styles.productName}>{product?.name}</h3>
                            <p className={styles.prodDesc}>{product?.description}</p>
                        </Col>
                        {product?.price !== 0 && product?.price &&
                            <Col xs={12} sm={6} className="p-0">
                                <div className={styles.prodPrice}>
                                    <span className={styles.prodPriceValue}>
                                        {product?.price?.toLocaleString()}
                                    </span>
                                    <span className={styles.prodPriceText}>INR</span>
                                </div>
                            </Col>
                        }
                    </Row>
                </>
                    :
                    <div className={`${styles.noDataDiv}`}>
                        {loading ?
                            <Col xs={12} className="p-0">
                                <Row style={{ height: "100%", width: "100%" }}>
                                    <Col className="">
                                        <h3
                                            className='p-4 mb-3 lazyLoad rounded'
                                            style={{ width: "120px" }}
                                        >{" "}</h3>
                                        <p className='p-2 mt-1 mb-1 lazyLoad rounded' />
                                        <p
                                            className='p-2 mt-0 lazyLoad rounded'
                                            style={{ width: "100px" }}
                                        />
                                    </Col>
                                    <Col className='d-flex justify-content-end align-items-start'>
                                        <div
                                            className='p-3 lazyLoad rounded'
                                            style={{ width: "100px" }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            :
                            <p className={`${styles.noDataText} text-light`} style={{ fontSize: "18px" }}>No Product Found.</p>
                        }
                    </div>
                }
                <Row className={styles.bottomProductsList}>
                    <Col className="p-0">
                        <ProductsWithFilter
                            loading={loading}
                            products={products ?? []}
                            selectedProductIndex={selectedProductIndex}
                            setSelectedProductIndex={setSelectedProductIndex}
                            categories={categories}
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                            lastProductElementRef={lastProductElementRef}
                            query={query}
                            setQuery={setQuery}
                        />
                    </Col>
                </Row>
            </Col>

        </>
    )
};

export default ProductInfo;