import React from 'react';
import styles from './Models.module.css';
import ModelItem from './ModelItem';

interface IProps {
    models: any;
    selectedModelIndex?: number;
    loading: boolean;
    handleClick?: any;
}

const ModelList: React.FC<IProps> = ({ models, loading, handleClick, selectedModelIndex }) => {
    return (
        <div className={`${styles.modelList} px-2`}>
            {loading ?
                Array(20).fill(0).map((_, idx) => (
                    <div
                        key={idx}
                        className='mb-2 p-1'
                    >
                        <div
                            className={`${styles.modelImage} lazyLoad`}
                            style={{ height: "100px" }}
                        >

                        </div>
                        <div
                            className={`py-3 rounded mt-1 lazyLoad`}
                        >

                        </div>
                    </div>
                ))
                :
                models.map((data: any, idx: number) => (
                    <ModelItem
                        key={idx}
                        index={idx}
                        data={data}
                        handleClick={handleClick}
                        selectedModelIndex={selectedModelIndex}
                    />
                ))
            }
        </div>
    )
}

export default ModelList;