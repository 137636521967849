import React, { useCallback, useRef } from 'react';
import styles from './Sidebar.module.css';

import ProductsWithFilter from '../../ProductsWithFilter';
import DashboardNav from '../DashboardNav';
import { FiltersView } from './Views';

interface IProps {
    loading: boolean;
    products: any;
    selectedProductIndex: number;
    setSelectedProductIndex: any;
    categories: any;
    selectedCategories: any;
    setSelectedCategories: any;
    query: string;
    setQuery: any;
    showFilter: boolean;
    setShowFilter: any;
    hasMore: boolean;
    setPageNumber: any;
}

const Sidebar: React.FC<IProps> = ({
    loading,
    products,
    selectedProductIndex,
    setSelectedProductIndex,
    categories,
    selectedCategories,
    setSelectedCategories,
    query,
    setQuery,
    showFilter,
    setShowFilter,
    hasMore,
    setPageNumber
}) => {
    const observer = useRef<any>();

    const lastProductElementRef = useCallback((node) => {
        if (loading) return;
        if (observer?.current) observer?.current?.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries?.[0].isIntersecting && hasMore) {
                setPageNumber((prevPageNumber: number) => prevPageNumber + 1);
            }
        });
        if (node) observer.current?.observe(node);
    }, [loading, hasMore, setPageNumber]);

    return (
        <div className={`${styles.sidebar} styledScrollbar pb-3`}>
            {showFilter ?
                <FiltersView
                    categories={categories}
                    setSelectedCategories={setSelectedCategories}
                    selectedCategories={selectedCategories}
                    setShowFilter={setShowFilter}
                />
                :
                <>
                    <DashboardNav
                        showFilter={showFilter}
                        setShowFilter={setShowFilter}
                        categories={categories}
                        selectedCategories={selectedCategories}
                    />
                    <div className='py-2' />
                    <ProductsWithFilter
                        loading={loading}
                        products={products ?? []}
                        selectedProductIndex={selectedProductIndex}
                        setSelectedProductIndex={setSelectedProductIndex}
                        categories={categories}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        lastProductElementRef={lastProductElementRef}
                        query={query}
                        setQuery={setQuery}
                    />
                </>
            }
        </div>
    )
}

export default Sidebar;