import React, { useCallback, useContext, useState } from 'react';
import { useApi } from './ApiContext';

const DataContext = React.createContext<any>({});

export function useData() {
    return useContext(DataContext);
}

const DataProvider = ({ children }: any) => {
    const {
        fetchViewer,
        fetchViewerGallery,
        fetchApplication
    } = useApi();
    const [viewerData, setViewerData] = useState<any>(null);
    const [viewerLoading, setViewerLoading] = useState<boolean>(false);
    const [viewerError, setViewerError] = useState<any>(null);
    const [galleryData, setGalleryData] = useState<any>(null);
    const [galleryLoading, setGalleryLoading] = useState<boolean>(true);
    const [appData, setAppData] = useState<any>(null);
    const [appLoading, setAppLoading] = useState<boolean>(true);

    const getViewerGallery = useCallback(async (viewerId: string) => {
        try {
            setGalleryLoading(true);
            const data = await fetchViewerGallery(viewerId);
            // console.log('Viewer Gallery - ', data);
            setGalleryData(data?._items?.[0]);
        } catch (err: any) {
            console.log(err);
            // window.alert(`Unable to fetch gallery for this viewer : ${err}`);
        } finally {
            setGalleryLoading(false);
        }
    }, [fetchViewerGallery]);

    const getViewer = useCallback(async (viewerId: string) => {
        try {
            setViewerLoading(true);
            const data = await fetchViewer(viewerId);
            // console.log('Viewer Data - ', data);
            if (data?.type !== 6) {
                const errMsg = "Provided viewer is not for Green Catalogue";
                throw errMsg;
            }
            setViewerData(data);
        } catch (err: any) {
            console.log(err);
            setViewerError(err);
            // window.alert(`Unable to fetch viewer : ${err}`);
        } finally {
            setViewerLoading(false);
        }
    }, [fetchViewer]);

    const getApplication = useCallback(async (appId: string) => {
        try {
            setAppLoading(true);
            const data = await fetchApplication(appId);
            // console.log('Application Data- ', data);
            setAppData(data);
        } catch (err: any) {
            console.log(err);
            window.alert(`Unable to fetch application data : ${err}`);
        } finally {
            setAppLoading(false);
        }
    }, [fetchApplication]);

    const data = {
        getViewer,
        viewerData,
        viewerLoading,
        viewerError,
        getViewerGallery,
        galleryData,
        galleryLoading,
        getApplication,
        appData,
        appLoading
    };

    return (
        <DataContext.Provider value={data}>
            {children}
        </DataContext.Provider>
    )
};

export default DataProvider;