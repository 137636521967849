import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './Dashboard.module.css';
import { MainSection, Sidebar } from '../../components/_dashboard';
import { useApi } from '../../contexts/ApiContext';
import { useParams } from 'react-router-dom';
import FullScreenError from '../../components/FullScreenError';
import { useData } from '../../contexts/DataContext';
import useProducts from '../../hooks/useProducts';
import useDebounce from '../../hooks/useDebounce';
import { useExtraData } from '../../contexts/ExtraDataContext';


const Dashboard: React.FC = () => {
    const {
        viewerData,
        getApplication,
        appData } = useData();
    const { categoryClicked } = useExtraData();
    const [modelsData, setModelsData] = useState<any>(null);
    const [modelLoading, setModelLoading] = useState<boolean>(true);
    const [selectedModelIndex, setSelectedModelIndex] = useState<any>(0);
    const { viewerId } = useParams<any>();
    const [categories, setCategories] = useState<any>([]);
    const [selectedCategories, setSelectedCategories] = useState<any>([]);
    const [query, setQuery] = useState<string>("");
    const { fetchReferenceProducts } = useApi();
    const observer = useRef<any>();
    const [showFilter, setShowFilter] = useState<boolean>(false);

    const debouncedQuery = useDebounce(query, 600);

    const {
        loading,
        error,
        hasMore,
        products,
        selectedProductIndex,
        setSelectedProductIndex,
        setPageNumber
    } = useProducts(debouncedQuery, selectedCategories);

    const lastProductElementRef = useCallback((node) => {
        if (loading) return;
        if (observer?.current) observer?.current?.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries?.[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        });
        if (node) observer.current?.observe(node);
    }, [loading, hasMore, setPageNumber]);


    const getReferenceProducts = useCallback(async (viewerId: string) => {
        try {
            setModelLoading(true);
            const data = await fetchReferenceProducts(viewerId);
            setModelsData(data);
        } catch (err: any) {
            console.log(err);
            window.alert(`Unable to fetch reference products : ${err}`);
        } finally {
            setModelLoading(false);
        }
    }, [fetchReferenceProducts]);

    useEffect(() => {
        if (viewerId)
            getReferenceProducts(viewerId);
    }, [viewerId, getReferenceProducts]);

    useEffect(() => {
        if (viewerData?.application) {
            getApplication(viewerData?.application);
        }
    }, [getApplication, viewerData]);

    useEffect(() => {
        if (appData && appData?.categories) {
            try {
                let parsedCategories = JSON.parse(appData?.categories);
                setCategories(parsedCategories);
            } catch (err) {
                window.alert("Unable to parse categories correctly");
            }
        }
    }, [appData]);

    useEffect(() => {
        if (categoryClicked) {
            setSelectedCategories([{ ...categoryClicked }]);
        } else {
            setSelectedCategories([]);
        }
    }, [categoryClicked]);

    return (
        <>
            {error &&
                <FullScreenError msg={error} />
            }
            <div className={`${styles.dashboard}`}>
                <Sidebar
                    loading={loading}
                    products={products ?? []}
                    selectedProductIndex={selectedProductIndex}
                    setSelectedProductIndex={setSelectedProductIndex}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    query={query}
                    setQuery={setQuery}
                    showFilter={showFilter}
                    setShowFilter={setShowFilter}
                    hasMore={hasMore}
                    setPageNumber={setPageNumber}
                />
                <MainSection
                    loading={modelLoading}
                    productsLoading={loading}
                    models={modelsData?._items ?? []}
                    product={products?.[selectedProductIndex] ?? null}
                    selectedModelIndex={selectedModelIndex}
                    setSelectedModelIndex={setSelectedModelIndex}

                    products={products ?? []}
                    selectedProductIndex={selectedProductIndex}
                    setSelectedProductIndex={setSelectedProductIndex}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    lastProductElementRef={lastProductElementRef}
                    query={query}
                    setQuery={setQuery}
                    showFilter={showFilter}
                    setShowFilter={setShowFilter}
                />
            </div>
        </>
    )
};

export default Dashboard;