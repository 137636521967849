import React from 'react';
import { Col, Spinner } from 'react-bootstrap';
import SingleProduct from './SingleProduct';
import styles from '../Main.module.css';

interface IProps {
    product: any;
    loading: boolean;
}

const ARView: React.FC<IProps> = ({ product, loading }) => {
    return (
        <>
            {product ?
                <div style={{ width: "100%", height: "100%" }}>
                    <Col xs={12} style={{ width: "100%", height: "100%" }}>
                        <SingleProduct
                            productData={product}
                            arButtonPosition={null}
                        />
                    </Col>
                </div>
                :
                <div className={`${styles.noDataDiv} border p-3`}>
                    {loading ?
                        <Spinner animation="border" />
                        :
                        <p className={`${styles.noDataText} text-light`}>No Products Available.</p>
                    }
                </div>
            }
        </>
    )
};

export default ARView;