import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

// import PrivateRoute from './components/PrivateRoute';
import ParentComponent from './components/ParentComponent';

import Home from './screens/Home/Home';
import Dashboard from './screens/Dashboard/Dashboard';
import PageNotFound from './screens/PageNotFound';
import About from './screens/About/About';
import Auth from './screens/Auth/Auth';
import { useData } from './contexts/DataContext';
import { useLogin } from './contexts/LoginContext';
import MoodboardCreator from './screens/MoodboardCreator';
// import Footer from './components/_footer/Footer';

function App() {
  const { viewerData } = useData();
  const { loggedIn } = useLogin();

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/:viewerId"
            element={<ParentComponent />}
          >
            <Route
              path={``}
              element={<Home />}
            />
            <Route
              path={`about-us`}
              element={<About />}
            />
            <Route
              path={`auth`}
              element={(!loggedIn && viewerData?.access_details?.authentication_enabled) ? <Auth /> : <PageNotFound />}
            />
            <Route
              path={`catalogue`}
              element={(loggedIn || !viewerData?.access_details?.authentication_mandatory) ? <Dashboard /> : <Navigate to={`/${viewerData?._id}/auth`} />}
            />
            <Route
              path={`moodboard-creator`}
              element={(loggedIn || !viewerData?.access_details?.authentication_mandatory) ? <MoodboardCreator /> : <Navigate to={`/${viewerData?._id}/auth`} />}
            />
          </Route>
          <Route
            path="*"
            element={<PageNotFound />}
          />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;
