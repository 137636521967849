import React, { useEffect, useState } from 'react';
import { ReservedImageKey } from '../../types';
import styles from './Products.module.css';

interface IProps {
    index: number;
    data: any;
    selectedProductIndex: number;
    setSelectedProductIndex: any;
    lastProductElementRef?: any;
    onProductClick?: (image: string) => void;
}

const ProductItem: React.FC<IProps> = ({
    index,
    data,
    selectedProductIndex,
    setSelectedProductIndex,
    lastProductElementRef,
    onProductClick
}) => {
    const [textureImage, setTextureImage] = useState<string>();

    useEffect(() => {
        let texture = data?.media_items?.find((item: any) => item?.name === ReservedImageKey.TEXTURE_IMAGE)?.url;
        if (texture) {
            setTextureImage(texture);
        } else {
            setTextureImage(undefined);
        }
    }, [data]);

    const selectProduct = () => {
        setSelectedProductIndex(index);
        if (!!onProductClick) {
            onProductClick(textureImage ?? data?.images?.[0] ?? data?.thumbnail);
        }
    };

    return (
        <>
            {lastProductElementRef ?
                <>
                    <div
                        className={`${styles.productItem} mb-2 p-1 rounded ${selectedProductIndex === index && !!!onProductClick && styles.active} ${!!onProductClick && styles.noHover}`}
                        onClick={selectProduct}
                        ref={lastProductElementRef}
                    >
                        <div className={`${styles.productImage}`}>
                            <img
                                src={data?.thumbnail}
                                alt={data?.name}
                            />
                        </div>
                        <p className={`${styles.productName} mb-0 p-0 py-1`} >
                            {data?.name?.substr(0, 12)}
                        </p>
                    </div>

                </>
                :
                <div
                    className={`${styles.productItem} mb-2 p-1 rounded ${selectedProductIndex === index && !!!onProductClick && styles.active} ${!!onProductClick && styles.noHover}`}
                    onClick={selectProduct}
                >
                    <div className={`${styles.productImage}`}>
                        <img
                            src={data?.thumbnail}
                            alt={data?.name}
                        />
                    </div>
                    <p className={`${styles.productName} mb-0 p-0 py-1`}>
                        {data?.name?.substr(0, 12)}
                    </p>
                </div>
            }
        </>
    )
}

export default ProductItem;