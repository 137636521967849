import React from 'react';
import styles from './Products.module.css';
import ProductItem from './ProductItem';

interface IProps {
    loading: boolean;
    products: any;
    selectedProductIndex: number;
    setSelectedProductIndex: any;
    lastProductElementRef: any;
    onProductClick?: (image: string) => void;
}

const ProductList: React.FC<IProps> = ({
    loading,
    products,
    selectedProductIndex,
    setSelectedProductIndex,
    lastProductElementRef,
    onProductClick
}) => {
    return (
        <div className={`${styles.productList} transparentScrollbar ${!!onProductClick && styles.modalProductList}`}>

            {products.map((data: any, idx: number) => {
                if (products?.length === idx + 1) {
                    return <ProductItem
                        key={idx}
                        index={idx}
                        data={data}
                        selectedProductIndex={selectedProductIndex}
                        setSelectedProductIndex={setSelectedProductIndex}
                        lastProductElementRef={lastProductElementRef}
                        onProductClick={onProductClick}
                    />
                }
                return <ProductItem
                    key={idx}
                    index={idx}
                    data={data}
                    selectedProductIndex={selectedProductIndex}
                    setSelectedProductIndex={setSelectedProductIndex}
                    onProductClick={onProductClick}
                />
            })}
            {loading &&
                Array(10).fill(0).map((_, idx) => (
                    <div
                        key={idx}
                        className='mb-2 p-1'
                    >
                        <div>
                            <div
                                className={`${styles.productImage} lazyLoad`}
                            >

                            </div>
                            <div className='p-2 mt-2 lazyLoad rounded'>

                            </div>
                        </div>
                    </div>
                ))
            }

        </div>
    )
}

export default ProductList;