import React, { useState, useMemo } from 'react';
import { Link, /*useNavigate,*/ useParams } from 'react-router-dom';
import { Image } from "react-bootstrap";
import { useData } from '../../../contexts/DataContext';
// import { useLogin } from '../../../contexts/LoginContext';
import styles from "./DashboardNav.module.css";
import DefaultLogoSrc from "../../../assets/images/brand_logo.svg";

const filterIcon = "/images/filter_svg.svg";
const filterActiveIcon = "/images/filter_active_svg.svg";

interface IProps {
    showFilter: boolean;
    setShowFilter: any;
    categories: any;
    selectedCategories: any;
}

const DashboardNav: React.FC<IProps> = ({
    showFilter,
    setShowFilter,
    categories,
    selectedCategories
}) => {
    const { viewerId } = useParams();
    const { viewerData } = useData();
    // const { logout, loggedIn } = useLogin();
    // const [showToast, setShowToast] = useState<boolean>(false);
    // const navigate = useNavigate();
    const [imageError, setImageError] = useState<boolean>(false);

    // const toggleToast = useCallback(() => {
    //     setShowToast(prev => !prev);
    // }, []);

    const filterCategories = useMemo(() => {
        let catNames: any = new Set(selectedCategories?.map((item: any) => item?.name));
        return [...catNames]?.join(', ');
    }, [selectedCategories]);

    return (
        <div className={`${styles.sideHead} p-4`}>
            <Link to={`/${viewerId}`} className='normalLink'>
                <div className={styles.logo}>
                    <Image
                        src={
                            imageError
                                ? DefaultLogoSrc
                                : viewerData?.brand_details?.logo
                                    ? viewerData?.brand_details?.logo
                                    : DefaultLogoSrc
                        }
                        className={imageError
                            ? styles.defaultLogoStyle
                            : viewerData?.brand_details?.logo
                                ? styles.image
                                : styles.defaultLogoStyle}
                        onError={() => {
                            setImageError(true);
                        }}
                    />
                </div>
            </Link>
            {categories && categories?.length > 0 &&
                <div className={`${styles.filterContainer}`}>
                    <div
                        className={`${styles.filterIcon}`}
                        onClick={() => setShowFilter(true)}
                    >
                        {!!filterCategories ?
                            <img src={filterActiveIcon} alt="" />
                            :
                            <img src={filterIcon} alt="" />
                        }

                    </div>
                </div>
            }
            {/* {viewerData?.access_details?.authentication_mandatory &&
                <div
                    className={styles.avatar}
                    onClick={toggleToast}
                >
                    <i className="fa-solid fa-user"></i>
                    {showToast &&
                        <div className={styles.toast}>
                            <ul>
                                {loggedIn &&
                                    <li
                                        onClick={() => {
                                            logout();
                                            navigate(`/${viewerId}`, { replace: true });
                                        }}
                                    >
                                        Logout
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                </div>
            } */}
        </div>
    )
};

export default DashboardNav;