import React from 'react';
import { MediaType } from '../../types';
import styles from './ImageGrid.module.css';
import { getImageSrc } from './ImageGridItem';

interface IProps {
    hide: () => void;
    mediaItem: any;
    prev: () => void;
    next: () => void;
}

const ImageModal: React.FC<IProps> = ({
    hide,
    mediaItem,
    prev,
    next
}) => {

    const GetMedia = (mediaItem: any) => {
        switch (mediaItem?.type) {
            case MediaType.IMAGE:
                return <img
                    src={mediaItem?.url}
                    alt={mediaItem?.name}
                />;
            case MediaType.VIDEO:
                return <video
                    controls
                    autoPlay
                    poster={getImageSrc(mediaItem)}
                >
                    <source src={mediaItem?.url} />
                </video>;
            case MediaType.PDF:
                return <embed src={mediaItem?.url} />;
            default:
                return <img
                    src={mediaItem?.url}
                    alt={mediaItem?.name}
                />;
        }
    };

    return (
        <div className={styles.imageModal}>
            <div
                className={styles.modalClose}
                onClick={hide}
            >
                <i className="fa-solid fa-xmark"></i>
            </div>
            <div className={`container rounded ${styles.modalContent}`}>
                <div className={styles.modalHead}>
                    {mediaItem?.name}
                </div>
                <div className={styles.modalImage}>
                    {GetMedia(mediaItem)}
                </div>
            </div>
            <div
                className={`${styles.prevBtn} ${styles.actionBtn}`}
                onClick={prev}
            >
                <i className="fa-solid fa-angle-left"></i>
            </div>
            <div
                className={`${styles.nextBtn} ${styles.actionBtn}`}
                onClick={next}
            >
                <i className="fa-solid fa-angle-right"></i>
            </div>
        </div>
    )
}

export default ImageModal;