import React, { useContext, useMemo } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import api from '../api/greenCatalog';

const LoginContext = React.createContext<any>({});

export function useLogin() {
	return useContext(LoginContext);
}

interface IProps {
	loggedIn: boolean;
	token: string;
	userId: string;
}
const KEY = "GREEN-CATALOG-TEMPLATE";
const initialState: IProps | null = null;

const LoginProvider = ({ children }: any) => {
	const [state, setState] = useLocalStorage(KEY, initialState);

	const requestOtp = async (email: string, phone: string, platform: string) => {
		const data = {
			...(email && { email: email }),
			...(phone && { phone_number: phone }),
			app_source: platform === "web" ? "Auto web" : "Auto mobile"
		};
		try {
			const response = await api.post('/auth/send_otp', data);
			return response?.data?.request_id;
		} catch (err: any) {
			if (err?.response?.data?.error) {
				throw err.response.data.error;
			}
			throw err;
		}
	};

	const verifyOtp = async (requestId: string, otp: string) => {
		const data = {
			request_id: requestId,
			otp: parseInt(otp)
		};
		try {
			const response = await api.post("/auth/verify_otp", data);
			return response?.data;
		} catch (err) {
			throw err;
		}
	};

	const loggedIn = useMemo(() => {
		return state?.token && state?.loggedIn;
	}, [state]);

	const logout = () => {
		setState(null);
	};

	const data = {
		loggedIn,
		state,
		setState,
		logout,
		requestOtp,
		verifyOtp
	};

	return (
		<LoginContext.Provider value={data}>
			{children}
		</LoginContext.Provider>
	)
};

export default LoginProvider;