import React, { useState } from 'react';
import { Col, Modal, Spinner } from 'react-bootstrap';
import Loader from '../../../Loader';
import ModelList from '../../../Models';
import styles from '../Main.module.css';
import SingleProduct from './SingleProduct';


interface IProps {
    loading: boolean;
    modelLoading: boolean;
    models: any;
    model: any;
    selectedModelIndex: number;
    setSelectedModelIndex: any;
    textureUrl: string;
}

const ThreeDView: React.FC<IProps> = ({
    loading,
    modelLoading,
    models,
    model,
    selectedModelIndex,
    setSelectedModelIndex,
    textureUrl
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const show = () => {
        setShowModal(true);
    };
    const hide = () => {
        setShowModal(false);
    };

    return (
        <>
            {modelLoading && <Loader />}
            <div>
                <button
                    className={`${styles.changeModaBtn} customOutlineBtn`}
                    onClick={show}
                >
                    change model
                </button>
            </div>
            {model ?
                <div style={{ width: "100%", height: "100%" }}>
                    <Col xs={12} style={{ width: "100%", height: "100%" }}>
                        <SingleProduct
                            productData={model}
                            textureUrl={textureUrl}
                            arButtonPosition={'none'}
                        />
                    </Col>
                </div>
                :
                <div className={`${styles.noDataDiv} border p-3`}>
                    {modelLoading ?
                        <Spinner animation="border" />
                        :
                        <p className={`${styles.noDataText} text-light`}>No 3D Models Available.</p>
                    }
                </div>
            }
            <Modal
                show={showModal}
                size="lg"
                onHide={hide}
                style={{ zIndex: 1500 }}
            >
                <Modal.Header closeButton className={`p-4 pb-3 text-large ${styles.modalHead}`}>
                    Choose 3D Model to visualize product
                </Modal.Header>
                <Modal.Body>
                    {models?.length === 0 ?
                        <div className={`${styles.noDataDiv} border p-3`}>
                            <p className={`${styles.noDataText} text-fav-grey`}>No 3D Models Available.</p>
                        </div>
                        :
                        <ModelList
                            loading={loading}
                            models={models}
                            selectedModelIndex={selectedModelIndex}
                            handleClick={(index: number) => {
                                setSelectedModelIndex(index);
                                hide();
                            }}
                        />
                    }
                </Modal.Body>
            </Modal>
        </>
    )
};

export default ThreeDView;