import React from 'react';
import styles from './Models.module.css';

interface IProps {
    index: number;
    selectedModelIndex?: number;
    data: any;
    handleClick?: any;
}

const ModelItem: React.FC<IProps> = ({ index, data, handleClick, selectedModelIndex }) => {
    return (
        <div
            className={`${styles.modelItem} rounded ${selectedModelIndex === index && styles.active}`}
        >
            <div
                className={`${styles.modelImage}`}
                onClick={() => handleClick(index)}
            >
                <img
                    src={data?.thumbnail}
                    alt={data?.name}
                />
            </div>
            <p className={`${styles.modelLabel} pb-0 mb-1`}>
                {data?.name}
            </p>
        </div>
    )
}

export default ModelItem;