import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { ReservedImageKey } from '../../../../types';
import ImageSlider from '../../../ImageSlider';
import styles from '../Main.module.css';

interface IProps {
    product: any;
    loading: boolean;
}

const ProductView: React.FC<IProps> = ({
    product,
    loading
}) => {
    const [heroImage, setHeroImage] = useState<string>();

    useEffect(() => {
        let hero = product?.media_items?.find((item: any) => item?.name === ReservedImageKey.HERO_IMAGE)?.url;
        if (hero) {
            setHeroImage(hero);
        } else {
            setHeroImage(undefined);
        }
    }, [product]);

    return (
        <>
            {product ?
                <div className={`${styles.productView}`}>
                    <Row style={{ height: "100%" }}>
                        <Col xs={12} className={styles.heroImage}>
                            <ImageSlider images={[heroImage ?? product?.images?.[0] ?? product?.thumbnail, ...(product?.images?.slice?.(1) ?? [])]} />
                        </Col>
                    </Row>
                </div>
                :
                <div className={`${styles.noDataDiv} border p-3`}>
                    {loading ?
                        <Spinner animation="border" />
                        :
                        <p className={`${styles.noDataText} text-light`}>No Products Available.</p>
                    }
                </div>
            }
        </>
    )
};

export default ProductView;