import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useData } from '../../contexts/DataContext';
import useDebounce from '../../hooks/useDebounce';
import useProducts from '../../hooks/useProducts';
import ProductsWithFilter from '../ProductsWithFilter';
import styles from "./ProductsModal.module.css";

interface IProps {
    showModal: boolean;
    hide: () => void;
    onProductClick: (image: string) => void;
}

const ProductsModal: React.FC<IProps> = ({
    showModal,
    hide,
    onProductClick
}) => {
    const {
        viewerData,
        getApplication,
        appData } = useData();
    const [categories, setCategories] = useState<any>([]);
    const [selectedCategories, setSelectedCategories] = useState<any>([]);
    const [query, setQuery] = useState<string>("");
    const debouncedQuery = useDebounce(query, 600);
    const observer = useRef<any>();

    const {
        loading,
        hasMore,
        products,
        selectedProductIndex,
        setSelectedProductIndex,
        setPageNumber
    } = useProducts(debouncedQuery, selectedCategories);

    const lastProductElementRef = useCallback((node) => {
        if (loading) return;
        if (observer?.current) observer?.current?.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries?.[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        });
        if (node) observer.current?.observe(node);
    }, [loading, hasMore, setPageNumber]);

    useEffect(() => {
        if (viewerData?.application) {
            getApplication(viewerData?.application);
        }
    }, [getApplication, viewerData]);

    useEffect(() => {
        if (appData && appData?.categories) {
            try {
                let parsedCategories = JSON.parse(appData?.categories);
                setCategories(parsedCategories);
            } catch (err) {
                window.alert("Unable to parse categories correctly");
            }
        }
    }, [appData]);

    return (
        <Modal
            show={showModal}
            size="lg"
            onHide={hide}
        >
            <Modal.Header closeButton className={`p-4 pb-3 text-large ${styles.modalHead}`}>
                Choose a product
            </Modal.Header>
            <Modal.Body>
                <div className={`${styles.modalBody} styledScrollbar`}>
                    <ProductsWithFilter
                        loading={loading}
                        products={products ?? []}
                        selectedProductIndex={selectedProductIndex}
                        setSelectedProductIndex={setSelectedProductIndex}
                        categories={categories}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        lastProductElementRef={lastProductElementRef}
                        onProductClick={onProductClick}
                        query={query}
                        setQuery={setQuery}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
};

export default ProductsModal;