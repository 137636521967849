import React from 'react';
import CategoryList from '../../../Categories';
import styles from "../Sidebar.module.css";

interface IProps {
    categories: any;
    setSelectedCategories: any;
    selectedCategories: any;
    setShowFilter: any;
}

const FiltersView: React.FC<IProps> = ({
    categories,
    setSelectedCategories,
    selectedCategories,
    setShowFilter
}) => {
    return (
        <>
            <div className={`${styles.filtersView}`}>
                <div className={styles.filtersHead}>
                    <div>Choose categories</div>
                    <i className="fa fa-times pointer" onClick={() => setShowFilter(false)}></i>
                </div>
                <CategoryList
                    categories={categories}
                    setSelectedCategories={setSelectedCategories}
                    selectedCategories={selectedCategories}
                />
            </div>
        </>
    )
}

export default FiltersView;