import React from 'react';
// import { Link, useParams } from 'react-router-dom';
import ProductList from '../../../Products';

interface IProps {
    loading: boolean;
    products: any;
    selectedProductIndex: number;
    setSelectedProductIndex: any;
    lastProductElementRef: any;
    onProductClick?: (image: string) => void;
}

const ProductsView: React.FC<IProps> = ({
    loading,
    products,
    selectedProductIndex,
    setSelectedProductIndex,
    lastProductElementRef,
    onProductClick
}) => {
    // const { viewerId } = useParams();
    return (
        <>
            {products?.length === 0 && !loading ?
                <p className='text-fav-grey'>No Products Available.</p>
                :
                <div className='py-3'>
                    <ProductList
                        loading={loading}
                        products={products}
                        selectedProductIndex={selectedProductIndex}
                        setSelectedProductIndex={setSelectedProductIndex}
                        lastProductElementRef={lastProductElementRef}
                        onProductClick={onProductClick}
                    />
                </div>
            }
        </>
    )
}

export default ProductsView;