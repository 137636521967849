import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;
const appToken:any = process.env.REACT_APP_APPLICATION_TOKEN;

export default axios.create({
    baseURL,
    headers:{
        "Content-Type":"application/json",
        authorization:appToken
    }
});