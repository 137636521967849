import React from 'react';
import styles from "./LandingScreenAnim.module.css";

const index = () => {
    return (
        <div className={styles.landingScreen}>
            <span data-content="HelloAR">HelloAR</span>
        </div>
    )
}

export default index;