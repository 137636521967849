import { useCallback, useEffect, useState } from 'react';
import { useApi } from '../contexts/ApiContext';
import { useData } from '../contexts/DataContext';


const useProducts = (
    query: string,
    selectedCategories: any
) => {
    const { fetchProducts } = useApi();
    const {viewerData} = useData();
    const [loading,setLoading] = useState<boolean>(true);
    const [error,setError] = useState<string|null>(null);
    const [hasMore,setHasMore] = useState<boolean>(false);
    const [pageNumber,setPageNumber] = useState<number>(1);
    const [products,setProducts] = useState<any>([]);
    const [selectedProductIndex, setSelectedProductIndex] = useState<number>(0);

    const getProducts = useCallback(async (appId : string) => {
        try {
            setLoading(true);
            setError(null);
            // console.log("page fetched- ", pageNumber);
            let categoryIds = [ ...selectedCategories?.map((category:any) => category?.id) ];
            const data = await fetchProducts(appId, pageNumber, query, categoryIds);
            // console.log('Product Data - ', data);
            if(pageNumber===1){
                setProducts((prevProducts:any)=>{
                    return [
                        ...data?._items
                    ]
                });
                setSelectedProductIndex(0);
            }else{
                setProducts((prevProducts:any)=>{
                    return [
                        ...prevProducts, ...data?._items
                    ]
                });
            }
            let haveMoreProductsToFetch = ((pageNumber - 1) * data?._meta?.max_results + data?._items?.length) < data?._meta?.total;
            setHasMore(haveMoreProductsToFetch);
            setLoading(false);
        } catch (err: any) {
            console.log(err);
            setError(err);
            // window.alert(`Unable to fetch products : ${err}`);
        }
    }, [fetchProducts, pageNumber, query, selectedCategories]);

    useEffect(()=>{
        setProducts([]);
        setPageNumber(1);
    },[query, selectedCategories]);

    useEffect(()=>{
        if(viewerData?.application){
            getProducts(viewerData?.application);
        }
    },[getProducts, viewerData, pageNumber, query, selectedCategories]);

    return {
        loading,
        error,
        hasMore,
        products,
        selectedProductIndex,
        setPageNumber,
        setSelectedProductIndex
    }
}

export default useProducts;