import React from 'react';
import { MediaType } from '../../types';
import styles from './ImageGrid.module.css';

const placeHolderUrl = "/images/placeholder.png";

interface IProps {
    index: number;
    show: (idx: number) => void;
    mediaItem: any;
}

export const getImageSrc = (mediaItem: any) => {
    if (mediaItem?.thumbnail) return mediaItem?.thumbnail;
    if (mediaItem?.type === MediaType.IMAGE) return mediaItem?.url;
    return placeHolderUrl;
};

const ImageGridItem: React.FC<IProps> = ({ index, show, mediaItem }) => {
    return (
        <div
            className={styles.imageGridItem}
            onClick={() => show(index)}
        >
            {mediaItem?.type === MediaType.VIDEO &&
                <div className={styles.playBtn}>
                    <i className="fa-solid fa-play"></i>
                </div>
            }
            {mediaItem?.type === MediaType.PDF &&
                <div className={styles.playBtn}>
                    <i className="fa-solid fa-file-pdf"></i>
                </div>
            }
            <img
                src={getImageSrc(mediaItem)}
                alt={``}
            />
        </div>
    )
}

export default ImageGridItem;