import React, { useEffect, useState } from 'react';
import Navbar from '../../components/_navbar/Navbar';
import styles from "./Home.module.css";
import { Image } from "react-bootstrap";

import { useNavigate, useParams } from 'react-router-dom';

import { useExtraData } from '../../contexts/ExtraDataContext';
import LandingScreenAnim from '../../components/LandingScreenAnim';
import { useData } from '../../contexts/DataContext';
import DefaultFeatureImageSrc from "../../assets/images/feature_image.svg";


const Home: React.FC = () => {
    const { firstTimeLandReport, setFirstTimeLandReport, setCategoryClicked } = useExtraData();
    const navigate = useNavigate();
    const { viewerId } = useParams();
    const { viewerData, getApplication, appData } = useData();
    const [categories, setCategories] = useState<any>([]);
    const [imageError, setImageError] = useState<boolean>(false);

    const onCategoryClicked = (cat: any) => {
        setCategoryClicked(cat);
        navigate(`/${viewerId}/catalogue`);
    };

    useEffect(() => {
        setCategoryClicked(undefined);
    }, [setCategoryClicked]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.scroll(0, 0);
        }
        setTimeout(() => {
            setFirstTimeLandReport(false);
        }, 2000);
    }, [setFirstTimeLandReport]);

    useEffect(() => {
        if (viewerData?.application) {
            getApplication(viewerData?.application);
        }
    }, [getApplication, viewerData]);

    useEffect(() => {
        if (appData && appData?.categories) {
            try {
                let parsedCategories = JSON.parse(appData?.categories);
                let validCategories = parsedCategories?.filter((cat: any) => !!cat.thumbnail);
                setCategories(validCategories);
            } catch (err) {
                window.alert("Unable to parse categories correctly");
            }
        }
    }, [appData]);

    if (firstTimeLandReport) {
        return <LandingScreenAnim />
    }

    return (
        <>
            <Navbar />
            <div className={`${styles.homeScreen}`}>
                <Image
                    src={
                        imageError
                            ? DefaultFeatureImageSrc
                            : viewerData?.brand_details?.feature_image
                                ? viewerData?.brand_details?.feature_image
                                : DefaultFeatureImageSrc
                    }
                    className={styles.image}
                    onError={() => {
                        setImageError(true);
                    }}
                    style={{ ...((imageError || !viewerData?.brand_details?.feature_image) && { objectFit: "contain" }) }}
                />
                <div className={styles.main}>
                    <div className={styles.mainHead}>
                        <h3>
                            Furniture Collections
                        </h3>
                        <p>
                            Fragments of luxury
                        </p>
                    </div>
                    <div className={styles.mainImages}>
                        {categories?.slice(0, 4)?.map((cat: any, idx: number) => (
                            <div
                                key={idx}
                                className={styles.mainImage}
                                onClick={() => onCategoryClicked(cat)}
                            >
                                <img
                                    src={cat?.thumbnail}
                                    alt=""
                                />
                                <div className={styles.imageText}>
                                    {cat?.name}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.mainBtns}>
                        <button
                            onClick={() => navigate(`/${viewerId}/catalogue`)}
                        >
                            View All Products
                        </button>
                        <button
                            onClick={() => navigate(`/${viewerId}/moodboard-creator`)}
                        >
                            View Moodboard
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;