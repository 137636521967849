import React, { useEffect, useMemo, useState } from 'react';
import { Image } from "react-bootstrap";
import { Link, /*useNavigate,*/ useParams } from 'react-router-dom';
import { useData } from '../../contexts/DataContext';
// import { useLogin } from '../../contexts/LoginContext';
import styles from './Navbar.module.css';
import DefaultLogoLightSrc from "../../assets/images/brand_logo_light.svg";
import DefaultLogoSrc from "../../assets/images/brand_logo.svg";

const Navbar = () => {
    // const { loggedIn, logout } = useLogin();
    const [screenWidth, setScreenWidth] = useState<number>();
    // const navigate = useNavigate();
    const { viewerId } = useParams();
    const { viewerData } = useData();
    const [imageError, setImageError] = useState<boolean>(false);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
        const listener: any = window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth);
        });
        return () => window.removeEventListener('resize', listener);
    }, []);

    const defaultLogo = useMemo(() => {
        return screenWidth && screenWidth <= 750 ? DefaultLogoSrc : DefaultLogoLightSrc;
    }, [screenWidth]);

    return (
        <>
            <div className={`${styles.navbar}`}>
                <Link to={`/${viewerId}`} className='normalLink'>
                    <div className={styles.logo}>
                        <Image
                            src={
                                imageError
                                    ? defaultLogo
                                    : viewerData?.brand_details?.logo
                                        ? viewerData?.brand_details?.logo
                                        : defaultLogo
                            }
                            className={imageError
                                ? styles.defaultLogoStyle
                                : viewerData?.brand_details?.logo
                                    ? styles.image
                                    : styles.defaultLogoStyle}
                            onError={() => {
                                setImageError(true);
                            }}
                        />
                    </div>
                </Link>
                <hr className={styles.midLine} />
                <Link to={`/${viewerId}/about-us`} className='normalLink'>
                    <div className={styles.navItem}>
                        About
                    </div>
                </Link>

                {/* <div className={`${styles.navList} ${navBarCollapsed && (showNavbar ? 'visible' : 'invisible')}`}>
                    {navBarCollapsed &&
                        <div className={styles.navBarHead}>
                            <Link to={`/${viewerId}`} className='normalLink'>
                                <div className={styles.logo}>
                                    <Image
                                        src={
                                            imageError
                                                ? DefaultLogoSrc
                                                : viewerData?.brand_details?.logo
                                                    ? viewerData?.brand_details?.logo
                                                    : DefaultLogoSrc
                                        }
                                        className={imageError
                                            ? styles.defaultLogoStyle
                                            : viewerData?.brand_details?.logo
                                                ? styles.image
                                                : styles.defaultLogoStyle}
                                        onError={() => {
                                            setImageError(true);
                                        }}
                                    />
                                </div>
                            </Link>
                            <div
                                className={styles.hamburger}
                                onClick={() => setShowNavbar(false)}
                            >
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                        </div>
                    }
                    <Link to={`/${viewerId}/about-us`} className='normalLink'>
                        <div className={styles.navItem}>
                            About us
                        </div>
                    </Link>
                    {viewerData?.access_details?.authentication_enabled &&
                        (loggedIn ?
                            <div className={styles.navItem}>
                                <button
                                    className={`btnOutlinePrimary`}
                                    onClick={() => {
                                        navigate(`/${viewerId}`, { replace: true });
                                        logout();
                                    }}
                                >
                                    Logout
                                </button>
                            </div>
                            :
                            <Link to={`/${viewerId}/auth`} className='normalLink'>
                                <div className={styles.navItem}>
                                    <button
                                        className={`btnPrimary`}
                                    >
                                        Login/Signup
                                    </button>
                                </div>
                            </Link>
                        )
                    }
                </div>
                <div
                    className={styles.hamburger}
                    onClick={() => setShowNavbar(true)}
                >
                    <i className="fa-solid fa-bars"></i>
                </div> */}
            </div>
        </>
    )
}

export default Navbar;