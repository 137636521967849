import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Image } from "react-bootstrap";
import ImageGrid from '../../components/ImageGrid';
import { useData } from '../../contexts/DataContext';
import { getSanitizedMediaItems } from '../../utilities';
import styles from "./About.module.css";
import DefaultLogoSrc from "../../assets/images/brand_logo.svg";
import DefaultFeatureImageSrc from "../../assets/images/feature_image.svg";


const About = () => {
    const navigate = useNavigate();
    const { viewerId } = useParams();
    const { galleryData, viewerData, galleryLoading } = useData();
    const [imageError, setImageError] = useState<boolean>(false);
    const [bgImageError, setBgImageError] = useState<boolean>(false);

    const scrollToGallery = () => {
        if (window) {
            window.scrollTo(0, window.innerHeight)
        }
    };

    return (
        <div className=''>
            <div className={styles.aboutScreen}>
                <div className={`${styles.aboutContainer} styledScrollbar`}>
                    <Image
                        src={
                            bgImageError
                                ? DefaultFeatureImageSrc
                                : viewerData?.brand_details?.feature_image
                                    ? viewerData?.brand_details?.feature_image
                                    : DefaultFeatureImageSrc
                        }
                        className={styles.bgImage}
                        onError={() => {
                            setBgImageError(true);
                        }}
                        style={{ ...((imageError || !viewerData?.brand_details?.feature_image) && { objectFit: "contain" }) }}
                    />
                    <div className={styles.aboutContent}>
                        <Link to={`/${viewerId}`} className='normalLink'>
                            <div className={styles.logo}>
                                <Image
                                    src={
                                        imageError
                                            ? DefaultLogoSrc
                                            : viewerData?.brand_details?.logo
                                                ? viewerData?.brand_details?.logo
                                                : DefaultLogoSrc
                                    }
                                    className={imageError
                                        ? styles.defaultLogoStyle
                                        : viewerData?.brand_details?.logo
                                            ? styles.image
                                            : styles.defaultLogoStyle}
                                    onError={() => {
                                        setImageError(true);
                                    }}
                                />
                            </div>
                        </Link>
                        <div className={styles.bio}>
                            <h3>About</h3>
                            {viewerData?.brand_details?.bio ?
                                <p className='mt-3'>
                                    {viewerData?.brand_details?.bio}
                                </p>
                                :
                                <p className='mt-3'>
                                    Here goes the description of your brand
                                </p>
                            }
                        </div>
                        <button
                            className={`customOutlineBtn mt-2 ${styles.viewCatalogBtn}`}
                            onClick={() => navigate(`/${viewerId}/catalogue`)}
                        >
                            View Catalogue
                        </button>
                        <div
                            className={styles.galleryBtn}
                            onClick={scrollToGallery}
                        >
                            <div style={{ color: "#000000" }}>
                                <i className="fa-solid fa-angle-up"></i>
                            </div>
                            <div style={{ color: "#000000" }}>
                                View Gallery
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.imageGrid}>
                    {/* <div className={styles.picHead}>Pictures</div> */}
                    <ImageGrid
                        mediaItems={getSanitizedMediaItems(galleryData?.media_items ?? [])}
                        loading={galleryLoading}
                        gridColumns={3}
                    />
                </div>
            </div>
        </div>
    )
}

export default About;